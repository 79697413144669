<template>
    <div class="event-page">
        <easy-news-item>
            <template v-slot:image>
                <img class="event-news-image" src="@/assets/images/events/image_events01@2x.png" />
            </template>
            <template v-slot:content>
                <easy-title class="lv-mb-24">
                    <div class="easy-news-title">
                        <div class="lv-mb-12">International Commercial & Legal</div>
                        <div>Cooperation Forum</div>
                    </div>
                </easy-title>
                <div class="lv-article-text">
                    <div class="lv-mb-24">
                        Held by China Council for the Promotion of International Trade, the International Commercial &
                        Legal Cooperation Forum has been an annual event since 2018 aiming at enhancing international
                        communication and cooperation in commercial and legal services. Lawback is the brother platform
                        of the forum, as well as the co-organizer, gathering Chinese enterprises and global lawyers for
                        barrier-free and better business cooperation.  With two years’ development, the forum has earned
                        a lot of popularity and has attracted participants and media from domestic and abroad with an
                        increasing number.
                    </div>
                    <div>
                        We hope all the countries can honor openness, cooperation inclusiveness and mutual benefit, so
                        as to take the advantage of economic globalization and the Belt and Road Initiative. Likewise, we
                        wish all the participants could learn from each other and contribute their innovative thoughts to
                        build this forum into:
                    </div>
                    <ul class="lv-list-content">
                        <li>A Research Platform for international investment and trade compliance theory</li>
                        <li>A Consultation Platform for international commercial and legal service cooperation</li>
                        <li>A Convergence Platform of global investment and trade information, news, policies and regulations</li>
                        <li>A Service Platform of legal promotion and guarantee for enterprises international operations</li>
                        <li>A Communication Platform for governments, enterprises and legal agencies</li>
                    </ul>
                </div>
            </template>
        </easy-news-item>
        <div class="events-lectures">
            <easy-title>
                <div class="events-lectures-title">Lectures</div>
            </easy-title>
            <div class="lv-mini-title lv-mt-16 lv-mb-24">Offline Lectures</div>
            <div class="lv-article-text lv-mb-24">
                Lawback has co-organized offline legal lectures with CCPIT branches in cities such as Beijing, Nanjing, Qingdao, Rizhao, Wuhan and Changsha until the end of 2019.
                Each lectures attracted over 150 participants from local enterprises. We spread legal knowledge and provide counsel.
            </div>
            <div>
                <swiper ref="lecturesSwiper" :options="lecturesSwiperOptions">
                    <swiper-slide  >
                        <div class="lv-images-wall-wrapper">
                            <img class="lv-images-wall-item" src="../../assets/images/events/image_offline01@2x.png">
                            <img class="lv-images-wall-item" src="../../assets/images/events/image_offline02@2x.png">
                            <img class="lv-images-wall-item" src="../../assets/images/events/image_offline03@2x.png">
                            <img class="lv-images-wall-item" src="../../assets/images/events/image_offline04@2x.png">
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="lv-images-wall-wrapper">
                            <img class="lv-images-wall-item" src="../../assets/images/events/image_offline05@2x.png">
                            <img class="lv-images-wall-item" src="../../assets/images/events/image_offline06@2x.png">
                            <img class="lv-images-wall-item" src="../../assets/images/events/image_offline07@2x.png">
                            <img class="lv-images-wall-item" src="../../assets/images/events/image_offline08@2x.png">
                        </div>
                    </swiper-slide>
                    <div class="lectures-pagination" slot="pagination"></div>
                </swiper>
            </div>
            <div class="lv-mini-title lv-mt-16 lv-mb-24">Online Lectures</div>
            <div class="lv-article-text lv-mb-24">
                Since the outbreak of COVID-19 on January, Joined hands with CCPIT and commerce associations and clubs, Lawback has invited experts and lawyers to give speeches
                on nearly 50 online letures with 1,3000 users' view in total, taking the advantage of "Internet +" and focusing on the increasing legal issues Chinese outbound investment
                and trade enterprises confronted with. Lawback contributes to fight against the pandemic and steady the international trade develpoment by its efforts.
            </div>
            <div>
                <swiper ref="onlineSwiper" :options="onlineSwiperOptions">
                    <swiper-slide  >
                        <div class="lv-images-wall-wrapper">
                            <img class="lv-images-wall-item" src="../../assets/images/events/image_online01@2x.png">
                            <img class="lv-images-wall-item" src="../../assets/images/events/image_online02@2x.png">
                            <img class="lv-images-wall-item" src="../../assets/images/events/image_online03@2x.png">
                            <img class="lv-images-wall-item" src="../../assets/images/events/image_online04@2x.png">
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="lv-images-wall-wrapper">
                            <img class="lv-images-wall-item" src="../../assets/images/events/image_online05@2x.png">
                            <img class="lv-images-wall-item" src="../../assets/images/events/image_online06@2x.png">
                            <img class="lv-images-wall-item" src="../../assets/images/events/image_online07@2x.png">
                            <img class="lv-images-wall-item" src="../../assets/images/events/image_online08@2x.png">
                        </div>
                    </swiper-slide>
                    <div class="online-pagination" slot="pagination"></div>
                </swiper>
            </div>
        </div>
        <div class="events-webinars lv-mb-24">
            <easy-title class="lv-mb-52">Webinars</easy-title>
            <div class="lv-article-text">
                Since June 2020, Lawback has been organized online seminars each month. 5-10 partners or attorneys from different countries will be invited to speak and discuss about
                specific topic at the webinar. Speakers can face-to-face talk freely with each other through online seminar, know about legal or investment situation in other countries and
                find out more efficient way to cooperate with external lawyers in relevant cases. Meanwhile, those webinars are livestreaming through meeting livestream to foreign
                auidences and wechat livestream tool to Chinese lawyers, in-house lawyers and company managers. Additionally, the webinar are pre-promote and post-promote on
                official homepage of Linkedin, facebook and wechat and also the website.
            </div>
        </div>
        <div>
            <swiper ref="webinarsSwiper" :options="webinarsSwiperOptions">
                <swiper-slide  >
                    <div class="lv-images-wall-wrapper">
                        <img class="lv-images-wall-item" src="../../assets/images/events/image_webinars01@2x.png">
                        <img class="lv-images-wall-item" src="../../assets/images/events/image_webinars02@2x.png">
                        <img class="lv-images-wall-item" src="../../assets/images/events/image_webinars03@2x.png">
                        <img class="lv-images-wall-item" src="../../assets/images/events/image_webinars04@2x.png">
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="lv-images-wall-wrapper">
                        <img class="lv-images-wall-item" src="../../assets/images/events/image_webinars05@2x.png">
                        <img class="lv-images-wall-item" src="../../assets/images/events/image_webinars06@2x.png">
                        <img class="lv-images-wall-item" src="../../assets/images/events/image_webinars07@2x.png">
                        <img class="lv-images-wall-item" src="../../assets/images/events/image_webinars08@2x.png">
                    </div>
                </swiper-slide>
                <div class="webinars-pagination" slot="pagination"></div>
            </swiper>
        </div>
        <div class="events-exclusive">
            <easy-title class="lv-mb-52">Exclusive Interview/Report</easy-title>
            <ul class="lv-list-content">
                <li class="events-exclusive-item lv-mb-24">Meet Ambassdor of Tanzania</li>
                <li class="events-exclusive-item lv-mb-24">Santoshi Pai: waht should enterprises pay attention to when investing India</li>
                <li class="events-exclusive-item lv-mb-24">Mustafa Jamal: Preferential policies for Chinese investors</li>
            </ul>
        </div>
    </div>
</template>

<script>

    import { swiper, swiperSlide,directive} from 'vue-awesome-swiper';
    import EasyTitle from "@/components/EasyTitle/EasyTitle";
    import EasyNewsItem from "@/components/EasyNewsItem/EasyNewsItem";

    import 'swiper/swiper.scss';
    export default {
        name: "events",
        components: {swiper, swiperSlide,EasyTitle,EasyNewsItem},
        data(){
            return {
                lecturesSwiperOptions:{
                  autoplay: 5000,
                  autoplayDisableOnInteraction:false,
                  setWrapperSize :true,
                  pagination : '.lectures-pagination',
                  loop:true,
                  paginationClickable :true,
                  mousewheelControl : true,
                },
                onlineSwiperOptions:{
                  autoplay: 5000,
                  autoplayDisableOnInteraction:false,
                  setWrapperSize :true,
                  pagination : '.online-pagination',
                  loop:true,
                  paginationClickable :true,
                  mousewheelControl : true,

                },
                webinarsSwiperOptions:{
                  autoplay: 5000,
                  autoplayDisableOnInteraction:false,
                  setWrapperSize :true,
                  pagination : '.webinars-pagination',
                  loop:true,
                  paginationClickable :true,
                },
            }
        },
        mounted() {
            this.bindHover(this.$refs.lecturesSwiper.$el);
            this.bindHover(this.$refs.onlineSwiper.$el);
            this.bindHover(this.$refs.webinarsSwiper.$el);

        },
        methods:{
            bindHover(el){
                let bullets = el.querySelectorAll(".swiper-pagination-bullet");
                bullets.forEach(function (item,i) {
                    item.onmouseenter=function () {
                        item.click();
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.event-page{
    width: 1200px;
    margin: 0 auto;
    padding-top: 80px;
    /*新闻图片*/
    .event-news-image{
        height: 596px;
    }
    .events-lectures{
        margin-top: 60px;
    }
    .lectures-pagination,.webinars-pagination,.online-pagination{
        margin: 12px auto 80px;
        text-align: center;
    }
    .events-exclusive{
        .events-exclusive-item{
            font-size: 20px;
            font-weight: bold;
            color: $content;
            line-height: 24px;
        }
    }

}


</style>